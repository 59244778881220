import {
  type ClientLoaderFunctionArgs,
  json,
  Link,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { type AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { $path } from 'remix-routes';

import {
  type DtoLearnerProfileResponse,
  type DtoSingleGamePackResponse,
} from '@lp-lib/api-service-client/public';

import { useLearningAnalytics } from '../analytics/learning';
import { useUserAnalytics } from '../analytics/user';
import { RequireActivation } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import { GlobalAudioDetectionProvider } from '../components/GameV2/apis/AudioDetection';
import { readGuestUserAccess } from '../components/GameV2/apis/GuestUser';
import { Overworld } from '../components/GameV2/Overworld';
import { OverworldBackground } from '../components/GameV2/overworld/OverworldBackground';
import { OverworldContinueButton } from '../components/GameV2/overworld/OverworldContinueButton';
import { OverworldDrawerManager } from '../components/GameV2/overworld/OverworldDrawer';
import { type OverworldDisplayOptions } from '../components/GameV2/overworld/types';
import { StackedSquaresIcon } from '../components/icons/StackedSquaresIcon';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider, useUser } from '../components/UserContext';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { apiService } from '../services/api-service';
import { OrganizerRoleUtils } from '../types';
import { type Game } from '../types/game';
import { fromDTOGamePack, fromDTOGames } from '../utils/api-dto';
import { isForbidden, isNotFound, tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);
  const id = action.params.id;
  if (!id) {
    throw new Error('expected gamepack id');
  }

  const guestUsers = url.searchParams.get('guest-users');

  let resp: AxiosResponse<DtoSingleGamePackResponse>;
  let profile: AxiosResponse<DtoLearnerProfileResponse>;
  try {
    [resp, profile] = await tokenWithRedirect(
      () =>
        Promise.all([
          apiService.gamePack.getGamePackById(id, {
            blocks: true,
            games: true,
            progression: 'authenticating-user',
            guest: (guestUsers ?? readGuestUserAccess()) === 'enabled',
          }),
          apiService.learning.getMyLearnerProfile({
            enrollmentsSummary: true,
            membershipsSummary: false,
          }),
        ]),
      action.request.url,
      {
        // for now, require authentication. in the future, we may permit unauthenticated access...
        requireAuthentication: true,
        preferRedirect: 'login',
      }
    );
  } catch (e) {
    if (isForbidden(e) || isNotFound(e)) {
      // treat both errors as a 404.
      throw json({}, { status: 404 });
    } else {
      throw e;
    }
  }

  return {
    bundle: resp.data,
    profile: profile.data,
  };
};

export function Component() {
  const data = useLoaderData<typeof clientLoader>();

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess allowGuests />,
    <RequireActivation />,
    <GlobalAudioDetectionProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <OverworldInternal {...data} />
    </ProvidersList>
  );
}

function OverworldInternal(props: {
  bundle: DtoSingleGamePackResponse;
  profile: DtoLearnerProfileResponse;
}) {
  const { gamePack, games, progression } = props.bundle;
  const user = useUser();
  const isOrgAdmin = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer?.role);

  const analytics = useLearningAnalytics();

  const navigate = useNavigate();

  const { call: handlePlayGame } = useLiveAsyncCall(async (game: Game) => {
    if (!progression) {
      await apiService.progression.createMyProgression(gamePack.id);
    }
    navigate(`/game-packs/${gamePack.id}/play/${game.id}`);
  });

  const displayOptions = useMemo<OverworldDisplayOptions>(() => {
    return {
      showLpLogo: false,
      disableBg: true,
      bottomSpacing: 'h-24',
      logoPlaceholderSpacing: 'h-12',
    };
  }, []);

  return (
    <div className='relative w-full h-full'>
      <OverworldBackground pack={fromDTOGamePack(gamePack)} />
      <Overworld
        pack={fromDTOGamePack(gamePack)}
        games={fromDTOGames(games || [])}
        progression={progression}
        onClickGame={(game) => {
          analytics.trackCourseStarted({
            packId: gamePack.id,
            packName: gamePack.name,
            groupId: game.id,
            groupName: game.name,
            isCourseCreator: gamePack.uid === user.id,
          });
          handlePlayGame(game);
        }}
        displayOptions={displayOptions}
      />
      <div className='fixed top-3.5 left-3 right-3 z-15 flex items-center justify-between text-white'>
        <OverworldDrawerManager
          initialProfile={props.profile}
          currentCourseId={gamePack.id}
        />
        {isOrgAdmin && (
          <Link to={$path('/learning/admin/my-courses')}>
            <StackedSquaresIcon />
          </Link>
        )}
      </div>
      <div className='fixed bottom-5 w-full flex justify-center z-10'>
        <OverworldContinueButton
          packId={gamePack.id}
          progression={progression}
        />
      </div>
    </div>
  );
}
